$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-video {
  position: relative;
  width: 100%;

  .sw-video__provider-link {
    top: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('/etc.clientlibs/corp-aem-sherwin/clientlibs/site/resources/sprite.svg#play-button-usage');
    background-position: center;
    background-repeat: no-repeat;
    background-size: rem(94) rem(94);
  }
  &__container {
    padding:56.25% 0 0 0;
    position:relative;
    &__iframe {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      border: none;
    }
  }
}
